<template>
    <router-view></router-view>
  </template>
  
  <script>
  export default {
    name: 'UserBibliography',
  };
  </script>

<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

</style>