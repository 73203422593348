<template>
  <div class="curriculum-vitae">
    <NavigationBar />
    <h1>Curriculum Vitae</h1>
    <div v-html="cvContent"></div>
  </div>
</template>

<script>
import NavigationBar from './NavigationBar.vue';

export default {
  name: 'CurriculumVitae',
  components: { 
    NavigationBar
  },
  data() {
    return {
      cvContent: `
        <h2>JOHN A.J. DUDLEY</h2>
        <h3>CURRICULUM VITAE</h3>
        <h4>TEACHING EXPERIENCE (AREAS OF COMPETENCE):</h4>
        <p>Courses I have taught at universities and university-level colleges in Belgium, the Netherlands, Germany, the USA, Chile, Spain and Georgia:</p>
        <ul>
          <li>History of Ancient Philosophy</li>
          <li>History of Medieval Philosophy</li>
          <li>History of Modern Philosophy</li>
          <li>History of 19th and 20th Century Philosophy</li>
          <li>Ethics</li>
          <li>Introduction to Philosophy</li>
          <li>Metaphysics</li>
          <li>Philosophy of Religion</li>
          <li>Philosophy of Nature</li>
          <li>Philosophy of Human Nature</li>
          <li>Comparative Religion</li>
          <li>Epistemology</li>
          <li>Ancient Sources of Western Medical Ethics</li>
          <li>Politics</li>
          <li>Latin</li>
          <li>Greek</li>
          <li>History of Political Thought in the Renaissance</li>
        </ul>
        <p>Born: Dublin, 18.07.1951, Irish (born) and Belgian (since 1983). Attended Primary and Secondary (High) School in Dublin (Ireland) 1959-1968. Took Latin for six years and Greek for five years.</p>
        <h4>UNIVERSITY DEGREES</h4>
        <ul>
          <li>M.A. in Latin and Greek (Classics) (1972, University College Dublin, Ireland)</li>
          <li>Licencié en Philosophie et Lettres (= M.A. in philosophy) (1978, Louvain, Belgium)</li>
          <li>Dr. Phil. in philosophy (1981, Cologne, Germany)</li>
          <li>PhD in philosophy (1997, Nymegen, Netherlands)</li>
        </ul>
        <h4>SCHOLARSHIPS AND PRIZES</h4>
        <p>Was awarded scholarships and prizes by University College Dublin (Ireland), the Italian government, the Belgian government, the Universities of Leuven and Cologne. The publication of my second doctorate (1997) was financed by the Radboud Foundation (Vught) and the Sormani Fund Foundation (Nymegen).</p>
        <h4>TEACHING AND RESEARCH EXPERIENCE</h4>
        <h5>PRESENT POSITION (since 2017):</h5>
        <p>UCLOUVAIN: COLLABORATEUR SCIENTIFIQUE</p>
        <h5>PREVIOUS POSITIONS</h5>
        <ul>
          <li>Visiting Professor at the Pontifical University of Lima (Peru), Oct.-Nov. 2019</li>
          <li>Visiting Professor at the KULublin (Poland), May 2018</li>
          <li>Visiting Professor, Ilia University, Tbilisi (Georgia), November 2015</li>
          <li>Visiting Professor, University of Córdoba (Spain), April 2015</li>
          <li>Visiting Professor, Benedictine College, Kansas (USA), March 2015</li>
          <li>Professor and Chairman, Philosophy Department, Fishermore College, Texas (USA) 2012-14</li>
          <li>Visiting Professor, University of Córdoba (Spain), May 2013</li>
          <li>Visiting Instructor at the Monastery of Mariawald, Germany (2010-2)</li>
          <li>Associate Professor at Benedictine College, Kansas (USA) (2008-9)</li>
          <li>Visiting Professor at Divine Word College, Iowa (USA) (2006-7)</li>
          <li>Visiting Professor at the Pontifical University of Santiago de Chile (Dec. 2004)</li>
          <li>Research Fellow at KULeuven (Belgium) in the De Wulf-Mansion Center for Ancient, Medieval and Renaissance Philosophy (2003-2017)</li>
          <li>Visiting Professor at Christus Sacerdos College (Belgium) (2003-4)</li>
          <li>Visiting Assistant Professor at the University of South Carolina (USA) (1999-2000)</li>
          <li>Assistant Professor at St. John’s College, ‘s Hertogenbosch, Netherlands (1989-1999)</li>
          <li>Assistant Professor, Marie Haps College, Brussels, Belgium (1990-94)</li>
          <li>Assistant Professor at Nymegen University, Netherlands (1982-6)</li>
          <li>High School teacher of Latin and Greek, Belgium, 1981</li>
          <li>One-year Lecturer Bonn University (1977-8), Assistant Cologne University (1976-7)</li>
        </ul>
        <h4>CONFERENCES</h4>
        <p>I have given conferences in the following thirty countries: Netherlands, Belgium, Ireland, England, Germany, Poland, Israel, Greece, Chile, Mexico, USA, Canada, Liechtenstein, Lithuania, Portugal, Hungary, Turkey, Italy, France, Japan, China, India, Spain, Brazil, Czech Republic, Georgia, Russia, Romania, Bulgaria, Peru.</p>
        <h4>MEMBERSHIP</h4>
        <ul>
          <li>Member of the Advisory Board of The International Journal of Philosophy</li>
          <li>Member of the Advisory Board of the Institut für thomistische Philosophie: <a href="http://itp.institute/?q=en/node/12" target="_blank">http://itp.institute/?q=en/node/12</a></li>
          <li>Member of the Editorial Board of Philosophy Study: <a href="http://www.davidpublisher.com/Home/Journal/PS" target="_blank">http://www.davidpublisher.com/Home/Journal/PS</a></li>
          <li>Member of the Société Internationale pour l’Etude de la Philosophie Médiévale (S.I.E.P.M.)</li>
          <li>Member of the American Philosophical Association</li>
        </ul>
        <h4>KNOWLEDGE OF LANGUAGES</h4>
        <p>Ancient: First-rate reading knowledge of 1. Greek, 2. Latin.</p>
        <p>Modern: I speak and read fluently and write correctly 3. English (native language), 4. Dutch, 5. German, 6. French.</p>
        <p>Studied at university and have taught through these languages for many years. I can also lecture through and read fluently 7. Italian and 8. Spanish. Fluent reading knowledge of 9. Modern Greek. Fairly good spoken and reading knowledge of 10. Russian.</p>
        <h4>PUBLICATIONS</h4>
        <h5>BOOKS</h5>
        <ul>
          <li>Gott und Θεωρία bei Aristoteles, Die metaphysische Grundlage der Nikomachischen Ethik, Peter Lang, Frankfurt a/M - Bern, 1982, 241pp.</li>
          <li>The Evolution of the Concept of Chance in the Physics and Ethics of Aristotle, A Commentary on Physics B 4-6, Acco, Amersfoort, 1997, 328pp.</li>
          <li>Dio e Contemplazione in Aristotele, Il Fondamento Metafisico dell’ ‘Etica Nicomachea’, Introduzione di Giovanni Reale, Traduzione di Giovanni Reale con la collaborazione di Vincenzo Cicero, Vita e Pensiero, Milan, 1999, XXVI-223pp.</li>
          <li>Aristotle’s Concept of Chance, - Accidents, Cause, Necessity and Determinism -, New York, State University of New York Press, 2012, xiv-469pp. Paperback edition: New York, State University of New York Press, 2013.</li>
        </ul>
         <h5>REFERRED ARTICLES</h4>
        <ol>
          <li>"Johannes Grammaticus Philoponus Alexandrinus, In Aristotelis De Anima, Proemion, translated from the Greek", in Bulletin de Philosophie Médiévale 16-17 (1974-5), pp. 62-85.</li>
          <li>"La contemplation (θεωρία) humaine selon Aristote", in Revue Philosophique de Louvain 80(1982), pp. 387-413.</li>
          <li>"The Love of God in Aristotle’s Ethics", in Neue Zeitschrift für Systematische Theologie und Religionsphilosophie, 25(1983), pp.126-137.</li>
          <li>"De historische scheiding tussen ethiek en wetenschap" in Nymegen Studies in the Philosophy of Nature and its Sciences, ed. G. Debrock, P. Kroes and P. Scheurer, 3(1983), pp. 121-143.</li>
          <li>"The Ethics of Democritus and Aristotle", in Proceedings of the First International Congress on Democritus, Xanthi 6-9 October 1983, Xanthi, 1984, pp. 371-385.</li>
          <li>"El sentido de la felicidad de la vida perfecta (bios teleios) en la Etica de Aristóteles", in Ethos (Buenos Aires) 10-11 (1982-3), pp. 39-54 (publ. 1984).</li>
          <li>"Substance, Nature and Time: A Criticism of Aristotle", in Nymegen Studies in the Philosophy of Nature and its Sciences 4(1985), Part II, ed. P. Kroes, pp.69-84.</li>
          <li>"Dialectic, Language and Reality in Aristotle", in Language and Reality in Greek Philosophy, Athens, 1985, pp.182-193.</li>
          <li>"The Concept of Substance and Life presupposed by Christianity", in El Humanismo y la Metafísica Cristiana en la Actualidad, Vol. II, Segundo Congreso Mundial de Filosofia Cristiana, Monterrey, 1986, pp. 539-553.</li>
          <li>"The Historical Origin of the Division between Ethics and Science - the Conflict between Aristotle and Christianity -", in El Humanismo y la Metafísica Cristiana en la Actualidad, Vol. V, Segundo Congreso Mundial de Filosofia Cristiana, Monterrey, 1986, pp.217-230.</li>
          <li>"De invloed van het begrip toeval bij Aristoteles op het godsbewijs vanuit de orde in het heelal", in Gehelen en Fragmenten, De vele gezichten van de filosofie, Acta van de 14 e Filosofiedag te Leuven, ed. Bart Raymaekers, Leuven, 1993, pp. 99-102.</li>
          <li>"Das betrachtende Leben (bios theoretikos) bei Platon und Aristoteles: ein kritischer Ansatz", in Neue Zeitschrift für Systematische Theologie und Religions-Philosophie, 37(1995), pp. 20-40.</li>
          <li>The articles listed under no’s 9. and 10. above were republished in Christian Humanism, International Perspectives, ed. Richard P. Francis and Jane E. Francis, New York, 1995, pp. 105-117 and pp. 187-199.</li>
          <li>"De hoeksteen van de samenleving", in Zaken die God raken 7(1996,1), pp. 13-16 (joint author R.G.L.M. Mutsaerts).</li>
          <li>"De Toekomst van het Christendom", in Quanta Cura 7(1997,4), pp.3-9.</li>
          <li>"Nuopolis ir Pakilimas", in Dienovidis (Lithuania), 46(1997) p.15 and 47(1997), pp. 14-15.</li>
          <li>"De Verhouding van Wetenschap en Ethiek bij Aristoteles", in Wijsgerig Perspectief 39(1998-9), 22-27.</li>
          <li>"De wijsheid van het christendom: een repliek op Nietzsche", in Communio 23(1998), 356-364.</li>
          <li>"Die Entdeckung Gottes", in Kirchliche Umschau 4/5(1999), 11-13.</li>
          <li>“Is it a Perfect World? Spinoza and the Principle of Perfection”, in Liber Amicorum Raphael de Smedt, Vol. IV, Litterarum Historia, Miscellanea Neerlandica 26, ed. A. Tourneux, Leuven, 2001, 161-178.</li>
          <li>“God as a Foundation of Plato’s and of Aristotle’s Ethics”, pp. 395-406, in New Images of Plato, Dialogues on the Idea of the Good, ed. Giovanni Reale and Samuel Scolnicov, Academia Verlag (Germany), 2002.</li>
          <li>“Aristotle’s Refutation of Determinism (Met. E3)”, pp. 133-150 in Indubitanter ad Veritatem, Studies offered to Leo J. Elders (Festschrift), ed. J. Vijgen, Budel, 2003.</li>
          <li>“Ethical and Metaphysical Foundations of the Rejection of Determinism by Aquinas”, in Verbum, Acta Neolatina (Budapest), VI (2004, 2), 315-324.</li>
          <li>“Happiness, Human Nature and Teleology in Ancient and Mediaeval Philosophy”, pp. 349-355, in M.C. Pacheco and J.F. Meirinhos (eds.), Intellect et Imagination dans la Philosophie Médiévale, Actes du XIe Congrès International de Philosophie Médiévale de la Société Internationale pour l’Etude de la Philosophie Médiévale (S.I.E.P.M.), Porto, du 26 au 31 août 2002, vol. IV, Mediaevalia. Textos e Estudos 23, Porto, 2004, 335-341.</li>
          <li>“The Meaning of Nous in the Ethics of Aristotle”, in Bollettino Filosofico (Calabria), 20(2004), 25-39.</li>
          <li>“The Metaphysical Presuppositions of Freedom”, in Proceedings of the International Congress on Christian Humanism in the Third Millennium: The Perspective of Thomas Aquinas, 21-25 September 2003, Vatican City, 2005, Vol. II, pp. 287-295.</li>
          <li>“Necessity and Chance: Aristotle’s Criticism of the Presocratics”, in Tyche-Ananke, Hasard et nécessité dans la philosophie grecque, Athens, Academy of Athens, 2005, pp. 92-110.</li>
          <li>“Evolution de la pensée aristotélicienne du plaisir”, in Le Plaisir, Réflexions antiques, approches modernes, ed. René Lefebvre and Laurence Villard, Presses Universitaires de Rouen, 2006, pp. 87-99.</li>
          <li>“Newman’s Criticism of Aristotle’s Thesis that Science is superior to Ethics”, in Angelicum 88(2011), 465-479.</li>
          <li>“A Revised Interpretation of the Mediaeval Reception of Aristotle’s Metaphysics”, pp. 1197-1201, in Universality of Reason. Plurality of Philosophies in the Middle Ages. XII Congresso Internazionale di Filosofia Medievale, Palermo 17-22 settembre 2007. Vol. II.2. Communicazioni. Latina. Ed. Alessandro Musco, Carla Compagno, Salvatore d’Agostino, and Giuliana Musotto, Palermo, Officina di Studi Medievali, 2012.</li>
          <li>“Aristotle’s Metaphysics as Mysticism”, in Tattva 8(2012), 99-119.</li>
          <li>“The Fate of Providence and Plato’s World Soul in Aristotle”, pp. 59-74, in Fate, Providence and Moral Responsibility in Ancient, Medieval and Early Modern Thought, Studies in Honour of Carlos Steel, ed. Pieter d'Hoine and Gerd Van Riel, Leuven University Press, 2014.</li>
          <li>“Aristotle's Concept of Chance and its Importance for Human Responsibility”, pp. 7-35, in Sobre Responsabilidade, ed. Denis Coitinho and João Hobuss, Pelotas, 2014 (Proceedings of the III Congresso Internacional de Filosofía Moral e Política sobre Responsabilidade, held at the Universidade Federal de Pelotas, Brazil, Nov. 4-7, 2013.)</li>
          <li>“Congratulations! Congratulations to Fr. Leo Elders SVD on the occasion of his ninetieth birthday”, pp. 114-120, in Libenter Praeceptorum Laudes Celebrarem, Stories and Reflections in Honor of Leo J. Elders S.V.D., ed. Jörgen Vijgen, Kerkrade, 2016.</li>
          <li>“Za udovolstviyata ot antichnata philosophiya (For the Pleasures of Ancient Philosophy)”, in Literaturen Vestnik [Literary newspaper]. Sofia, Literaturen Vestnik Foundation 26(2017) nr. 3 (25-31 Jan. 2017), p. 5.</li>
          <li>“Aristotle on Teleology”, in Key Themes and Figures in Ancient and Medieval Philosophy (Gale Researcher Internet Library Reference Database), ed. Brendan Sweetman. Detroit, MI: Gale/Cengage, 2017, 4898 words.</li>
          <li>“Aristotle Doctrine of the Mean”, in Key Themes and Figures in Ancient and Medieval Philosophy (Gale Researcher Internet Library Reference Database), ed. Brendan Sweetman. Detroit, MI: Gale/Cengage, 2017, 2061 words.</li>
          <li>“The Theme of Eudaimonia in Aristotle”, in Key Themes and Figures in Ancient and Medieval Philosophy (Gale Researcher Internet Library Reference Database), ed. Brendan Sweetman. Detroit, MI: Gale/Cengage, 2017, 3616 words.</li>
          <li>“The Superiority of Science to Ethics according to Aristotle and its Influence on Contemporary Science and Culture”, pp. 155-168, in The Influence of Hellenism on Contemporary Science, Culture and Education, ed. T.P. Minchenko, STT, Tomsk, 2016, 402pp. ISBN: 978-5-93629-575-1. Reprinted pp. 21-28, in The Influence of Hellenism on Contemporary Science, Culture and Education, Selected Reports of the International Multidisciplinary Conference, October 5-8, 2016, Tomsk, Russia; October 20-24, 2016, Moscow, Russia, ed. T.P. Minchenko, London, 2017, Red Square Scientific, ISBN 978-0-9928299-4-0.</li>
          <li>“Aristotle's Three Teleologies”, pp. 175-186, in The Legacies of Aristotle as Constitutive Element of European Rationality. Proceedings of the Moscow International Conference on Aristotle. RAS Institute of Philosophy, October 17-19, 2016. Ed. Valery V. Petroff. Moscow, Aquilo Press, 2017.</li>
          <li>“Физические и метафизические основания этики у Аристотеля“ (“The Physical and Metaphysical Foundations of Aristotle's Ethics”), in Verbum 19 (2017), 9-23. (Proceedings of the International Conference on Aristotle's Ethics, held in St. Petersburg, Nov. 2016).</li>
          <li>“Aristotle's Views on Chance and their Contemporary Relevance”, in Schole 12(2018), 7-27. (Proceedings of the International Conference on Aristotle held in Novosibirsk Sept. 2017)</li>
          <li>“Aristotle on the Divine”, in предизвикателството Аристотел (Aristotle: The Challenge), pp. 23-36. Gicheva-Gocheva, Dimka, Ivan Kolev, and Haralambi Panicidis (eds.). Sofia: University Press “St. Kliment Ohridsky”, 2018. (Proceedings of the International Conference on Aristotle held in Sofia, 28-30 Nov. 2016).</li>
          <li>“The Unrealism of Aristotle's Metaphysics?” in Proceedings of the XXIII World Congress of Philosophy, Vol. II, Section II Classical Greek Philosophy, 95-99, ed. Konstantinos Boudouris, 2018, published on-line, ISBN 978-1-63435-038-9.</li>
          <li>“Fundamentos físicos y metafísicos de la ética para Aristóteles,” in Arete, Revista de Filosofía, 30(2018), 7-21.</li>
          <li>“A Reinterpretation of Aristotle's metaphysics”, in Philosophia 48(2018), 152-164.</li>
          <li>“Plato's Concept of Chance”, in Платоновские исследования – Вып. 9 (2018 / 2), 56-71. (Proceedings of the congress of the International Plato Society in St. Petersburg, Aug. 28-30, 2018).</li>
          <li>“God's Pleasure and Man's Pleasure according to St. Thomas Aquinas”, in Divus Thomas 121, 3 (2018), 178-188. (Proceedings of the 6th International Conference of the International Society of Thomas Aquinas (SITA) held in Bologna April 20-22, 2017 on The Human Person).</li>
          <li>“What is the Meaning of Ethics for Aristotle?” pp. 227-232, in Aristotle, 2400 Years, Proceedings of the World Congress May 23-28, 2016, ed. Demetra Sfendoni-Mentzou, Thessaloniki, 2019, ISBN 978-960-243-716-2.</li>
          <li>“The Theology of Aristotle's Ethics and Politics”, pp. 29-45, in 2400 Years of Thinking with Aristotle, ed. Mircea Dumitru, Cristian Iftode, and Savu Totu, Editura Universitãtü din Buchuresti (Bucharest University Editions), 2020. ISBN 978-606-16-1199-7.</li>
          <li>“Neoplatonism confronted by Aristotelianism in Western Christianity”, in “Héritages platoniciens et aristotéliciens dans l'Orient et l'Occident (IIe-XVIe siècles), Actes du colloque de Tbilissi, juin 2019”, Wiesbaden, Reichert Verlag (Scrinium Friburgense Band 54), 2021, 211-223.</li>
          </ol>
      `
    };
  }
};
</script>

<style scoped>
html, body {
  height: 100vh; /* Ensure the full viewport height is used */
  margin: 0;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

h1, h2, h3, h4, h5 {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 10px;
}

h2 {
  font-size: 1.8em;
}

h3 {
  font-size: 1.5em;
}

h4 {
  font-size: 1.2em;
}

h5 {
  font-size: 1.1em;
}

div {
  margin: 0 auto;
  padding: 20px;
  max-width: 800px;
  font-family: Arial, sans-serif;
}

ul, ol {
  margin: 20px 0;
  padding-left: 20px;
}

ul {
  list-style-type: square;
}

p, li {
  text-align: justify;
}

a {
  color: blue;
  text-decoration: underline;
}

a:hover {
  color: darkblue;
}
</style>