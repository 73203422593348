<template>
  <nav class="navbar">
    <router-link to="/" class="nav-item">Home</router-link>
    <router-link to="/curriculum-vitae" class="nav-item">Curriculum Vitae</router-link>
    <router-link to="/bibliography" class="nav-item">Bibliography</router-link>
    <router-link to="/publications" class="nav-item">Publications</router-link>
    <router-link to="/life/poems" class="nav-item">Poems</router-link>
  </nav>
</template>

<script>
export default {
  name: 'NavigationBar',
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');

.navbar {
  display: flex;
  justify-content: center;
  background-color: transparent; /* Darker shade for background */
  padding: 1.5rem 0;
  /*box-shadow: 0 2px 5px rgba(0,0,0,0.2);*/ /* Subtle shadow for depth */
  font-family: 'Roboto', sans-serif; /* Google Font */
  /* size should be vertical 15% */
  height: 25%;
  z-index: 10;
}

.nav-item {
  color: #ECF0F1; /* Improved contrast */
  margin: 0 1.5rem; /* Increased spacing */
  text-decoration: none;
  transition: color 0.3s ease, transform 0.3s ease; /* Enhanced hover effect */
}

.nav-item:hover {
  color: #1a70bc; /* Brighter hover color */
  transform: translateY(-2px); /* Slight lift effect on hover */
}

@media (max-width: 768px) {
  .navbar {
    flex-direction: column; /* Stack nav items vertically on smaller screens */
  }
  .nav-item {
    margin: 0.5rem 0; /* Adjust spacing for vertical layout */
  }
}
</style>