import { createRouter, createWebHistory } from 'vue-router';
import HomePage from './components/HomePage.vue';
import CurriculumVitae from './components/CurriculumVitae.vue';
import UserBibliography from './components/UserBibliography.vue';
import UserPublications from './components/UserPublications.vue';
import UserPoems from './components/UserPoems.vue';

const routes = [
  { path: '/', component: HomePage },
  { path: '/curriculum-vitae', component: CurriculumVitae },
  { path: '/bibliography', component: UserBibliography },
  { path: '/publications', component: UserPublications },
  { path: '/life/poems', component: UserPoems },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;