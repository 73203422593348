<template>
  <div>
    <NavigationBar />
    <h1>This is a work in progress</h1>
    <p>Details about the user bilbiography.</p>
  </div>
</template>

<script>
import NavigationBar from './NavigationBar.vue';
export default {
  name: 'UserBibliography',
  components: { 
    NavigationBar
  }
};
</script>

