<template>
  <div class="home-container">
    <!-- Removed NavigationBar from background div -->
    <div class="background"></div>
    <!-- Placed NavigationBar outside but above the background div -->
    <NavigationBar class="navbar"/>
  </div>

  <div class="content miletus">
    <h5>Miletus (2015)</h5>
  </div>
</template>

<script>
import NavigationBar from './NavigationBar.vue';

export default {
  name: 'HomePage',
  components: {
    NavigationBar
  }
};
</script>

<style scoped>
/* Scoped reset for demonstration; ideally, use a global reset */
html, body {
  margin: 0;
  padding: 0;
}

.home-container {
  position: relative;
  height: 100vh;
  width: 100%;
  overflow: hidden;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url('/public/ruins.jpg') no-repeat center center;
  background-size: cover;
  z-index: -1;
}

/* Ensured navbar is visually the same by maintaining its position and z-index */
.navbar {
  position: absolute; /* Changed from relative to absolute */
  top: 0; /* Align to the top of the home-container */
  width: 100%; /* Ensure it spans the full width */
  z-index: 2; /* Keep it above the background */
}

.content {
  position: relative;
  z-index: 2;
  text-align: center;
  margin-top: 2rem;
  color: white;
}

.content h1, .content p {
  margin: 0;
}

.miletus {
  /* LEFT BOTTOM, white letters'*/
  position: absolute;
  bottom: 11%;
  left: 4%;
  width: 15%;
  background-color: transparent;
  padding: 1rem;
  font-size: 1.5rem;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}
</style>